import { useForm } from 'react-hook-form';
import { InputStateWrapper as HookedInput } from '../../admin/form-components/InputStateWrapper.tsx';
import { ExtendedUserDto, ModulePrivileges } from '../../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import { useEffect } from 'react';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { DropdownStateWrapper } from '../form-components/DropdownStateWrapper.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';

export interface UserDto
  extends Omit<
    ExtendedUserDto,
    | 'updatedAt'
    | 'createdAt'
    | 'id'
    | 'allow'
    | 'allowOneDayReminder'
    | 'allowSevenDaysReminder'
  > {
  password?: string;
}

interface UserFormProps {
  value?: UserDto;
  onSubmit: (s: UserDto) => void;
  showPassword?: boolean;
}

const PRIVILEGES_MEMBER = [ModulePrivileges.Member];
const PRIVILEGES_LECTURER = [ModulePrivileges.Member];
const PRIVILEGES_ADMIN = [
  ModulePrivileges.Member,
  ModulePrivileges.User,
  ModulePrivileges.Categories,
  ModulePrivileges.Company,
  ModulePrivileges.Flags,
  ModulePrivileges.Seminar,
  ModulePrivileges.Site,
  ModulePrivileges.PublicSeminarPageFields,
  ModulePrivileges.SignUpForm,
];

export const UserForm: React.FC<UserFormProps> = ({
  value,
  onSubmit,
  showPassword,
}) => {
  const { control, formState, handleSubmit, reset } = useForm<UserDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: value,
  });

  const onFormSubmit = (values: UserDto) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (
      JSON.stringify(PRIVILEGES_MEMBER.sort()) ===
      JSON.stringify(value?.privileges.sort())
    ) {
      reset({ ...value, privileges: PRIVILEGES_MEMBER });
    } else if (
      JSON.stringify(PRIVILEGES_LECTURER.sort()) ===
      JSON.stringify(value?.privileges.sort())
    ) {
      reset({ ...value, privileges: PRIVILEGES_LECTURER });
    } else if (
      JSON.stringify(PRIVILEGES_ADMIN.sort()) ===
      JSON.stringify(value?.privileges.sort())
    ) {
      reset({ ...value, privileges: PRIVILEGES_ADMIN });
    } else {
      reset({ ...value, privileges: undefined });
    }
  }, [JSON.stringify(value)]);

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="Benutzer bearbeiten" />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Profil"
          description="Bearbeiten Sie die Profil-Informationen zu einem Benutzer."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <DropdownStateWrapper
              options={[
                { text: 'Herr', value: 'MR' },
                { text: 'Frau', value: 'MS' },
              ]}
              name={'title'}
              label={'Anrede'}
              rules={{ required: true }}
              control={control}
            />
            <HookedInput
              name={'firstName'}
              label={'Vorname'}
              placeholder="Lisa"
              control={control}
            />
            <HookedInput
              name={'lastName'}
              label={'Nachname'}
              placeholder="Musterfrau"
              control={control}
            />
            <HookedInput
              name={'email'}
              label={'E-Mail'}
              placeholder="test@example.com"
              control={control}
            />
            {showPassword && (
              <HookedInput
                name={'password'}
                label={'Passwort'}
                rules={{ required: true }}
                control={control}
              />
            )}
          </div>
        </Fieldset>
        <Fieldset
          legend="Berechtigungen"
          description="Über welche Berechtigungen verfügt der Benutzer?"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <DropdownStateWrapper
              label="Rolle"
              options={[
                {
                  text: 'Seminarteilnehmer',
                  value: PRIVILEGES_MEMBER,
                },
                {
                  text: 'Dozent',
                  value: PRIVILEGES_LECTURER,
                },
                {
                  text: 'Administrator',
                  value: PRIVILEGES_ADMIN,
                },
              ]}
              name={'privileges'}
              control={control}
              required={true}
              rules={{ required: true }}
            />
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
