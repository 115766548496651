import { useQuery } from 'urql';
import {
  Grid,
  GridColumn,
  GridFilterChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { graphql } from '../../gql';
import React, { useState } from 'react';
import {
  filterBy,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { Link } from 'wouter';
import { PageTitle } from '../PageTitle.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';

interface SeminarsPageProps {
  companyId: string;
}

const SeminarsQuery = graphql(/* GraphQL */ `
  query getSeminars($companyId: String!) {
    seminars(companyId: $companyId) {
      id
      seminarNumber
      title
      subtitle
      startDate
      endDate
      description
    }
  }
`);

export const SeminarsPage: React.FC<SeminarsPageProps> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }] = useQuery({
    query: SeminarsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const parsedData =
    data?.seminars.map((seminar) => ({
      ...seminar,
      startDate: new Date(seminar.startDate),
      endDate: new Date(seminar.endDate),
    })) ?? [];

  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'and',
    filters: [],
  });
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  const filterdData = filterBy(parsedData, filter);
  return (
    <>
      <PageTitle title="Seminare" />
      <Grid
        className="h-auto [&_.k-grid-norecords-template]:border-0"
        data={filterdData.slice(page.skip, page.take + page.skip)}
        filterable={true}
        filter={filter}
        onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
        pageable
        take={page?.take}
        skip={page?.skip}
        total={filterdData.length}
        onPageChange={(event) => {
          setPage(event.page);
        }}
      >
        <GridNoRecords>Keine Seminare gefunden</GridNoRecords>
        <GridColumn field="seminarNumber" title="Seminar Nr." />
        <GridColumn field="title" title="Titel" />
        <GridColumn field="subtitle" title="Untertitel" />
        <GridColumn
          field="startDate"
          title="Startdatum"
          filter="date"
          format="{0:dd.MM.yyyy hh:mm}"
        />
        <GridColumn
          field="endDate"
          title="Enddatum"
          filter="date"
          format="{0:dd.MM.yyyy hh:mm}"
        />
        <GridColumn
          width={'120px'}
          filterable={false}
          cell={(props) => (
            <td className={'k-button-link-secondary flex space-x-2'}>
              <Link
                href={`/seminars/edit/${props.dataItem.id}`}
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md text-red"
              >
                <HeroIcon
                  name="Pencil"
                  className="block h-5 w-5"
                  aria-label="Bearbeiten"
                />
              </Link>
              <a
                target="_blank"
                href={`/seminar/${props.dataItem.id}`}
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md text-red"
              >
                <HeroIcon
                  name="GlobeAlt"
                  className="block h-5 w-5"
                  aria-label="Öffentliche Seite öffnen"
                />
              </a>
            </td>
          )}
        />
      </Grid>
    </>
  );
};
