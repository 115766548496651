import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import {
  Editor,
  EditorChangeEvent,
  EditorProps,
  EditorTools,
} from '@progress/kendo-react-editor';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  UnorderedList,
  OrderedList,
  FormatBlock,
  Unlink,
  InsertImage,
  Link,
} = EditorTools;

interface EditorStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<EditorProps, 'defaultValue' | 'name'> {
  hint?: string;
  label?: string;
  optional?: boolean;
}

export const EditorStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  hint,
  label,
  optional,
}: EditorStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const { onChange, value, ...restField } = field;
  const id = field.name;
  const showValidationMessage = Boolean(isTouched && error?.message);
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper className="self-start">
      <Label
        editorId={id}
        editorValid={!showValidationMessage}
        editorDisabled={disabled}
        optional={optional}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <Editor
          value={value ?? ''}
          onChange={(event: EditorChangeEvent) => {
            onChange(event?.html);
          }}
          tools={[
            [Bold, Italic, Underline, Strikethrough],
            FormatBlock,
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent, OrderedList, UnorderedList],
            [Link, Unlink, InsertImage],
          ]}
          contentStyle={{
            height: 200,
          }}
          {...restField}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{error?.message}</Error>}
      </div>
    </FieldWrapper>
  );
};
