import { TreeViewExpandChangeEvent } from '@progress/kendo-react-treeview';
import { ModulePrivileges } from '../gql/graphql';
import { IAdminMenuItem } from './AdminMenuItem';

// This menu data structure is the default value for the menu. It contains/defines which privileges are necessary to see a page
export const menu: IAdminMenuItem[] = [
  {
    text: 'Einstellungen',
    expanded: true,
    icon: 'Cog6Tooth',
    items: [
      {
        text: 'Allgemein',
        href: '/general',
        icon: 'Home',
        privilege: ModulePrivileges.Company,
      },
      {
        text: 'Inhaltsblöcke',
        href: '/public-seminar-page-fields',
        icon: 'PresentationChartBar',
        privilege: ModulePrivileges.PublicSeminarPageFields,
      },
      {
        text: 'Flags',
        href: '/flags',
        icon: 'Flag',
        privilege: ModulePrivileges.Flags,
      },
      {
        text: 'E-Mail',
        href: '/mailsettings',
        icon: 'Envelope',
        privilege: ModulePrivileges.Company,
      },
      {
        text: 'Anmeldeoptionen',
        href: '/sign-up-form-elements',
        icon: 'Identification',
        privilege: ModulePrivileges.SignUpForm,
      },
      {
        text: 'Anmeldegruppen',
        href: '/sign-up-form-select',
        icon: 'UserGroup',
        privilege: ModulePrivileges.SignUpForm,
      },
      {
        text: 'Banner',
        href: '/banner',
        icon: 'Megaphone',
        privilege: ModulePrivileges.Company,
      },
    ],
  },
  {
    text: 'Stammdaten',
    icon: 'Key',
    expanded: true,
    items: [
      {
        text: 'Seiten',
        href: '/sites',
        icon: 'Newspaper',
        privilege: ModulePrivileges.Site,
      },
      {
        text: 'Kategorien',
        href: '/categories',
        icon: 'Tag',
        privilege: ModulePrivileges.Categories,
      },
      {
        text: 'Benutzer',
        href: '/users',
        icon: 'UserCircle',
        privilege: ModulePrivileges.User,
      },
    ],
  },
  {
    text: 'Seminare',
    icon: 'Key',
    expanded: true,
    items: [
      {
        text: 'Alle Seminare',
        href: '/seminars',
        icon: 'UserGroup',
        privilege: ModulePrivileges.Seminar,
      },
    ],
  },
];

export const getMenuItemBySlug = (slug: string) =>
  findInMenuTree((item) => item.href === slug, menu);

// We apply a set of given privileges for a user to the default menu, so probably we end up disabling some menu items
const applyPrivilegesToMenu = (
  menu: IAdminMenuItem[],
  privileges: ModulePrivileges[],
) => {
  for (const item of menu) {
    // If it's a category/parent item -> never disable it (they can't be clicked anyway)
    if (item?.items?.length) {
      applyPrivilegesToMenu(item.items, privileges);
    } else {
      if (item.privilege && privileges.includes(item.privilege)) {
        item.disabled = false;
      } else {
        item.disabled = true;
      }
    }
  }
  return menu;
};

export const getFirstAllowedMenuItem = (privilegedMenu: IAdminMenuItem[]) => {
  return findInMenuTree((item) => !item.disabled, privilegedMenu);
};

const applyCurrentItemToMenu = (menu: IAdminMenuItem[], location: string) => {
  for (const item of menu) {
    if (item.href && location.endsWith(item.href)) {
      item.selected = true;
    } else {
      item.selected = false;
    }
    if (item?.items?.length) {
      applyCurrentItemToMenu(item.items, location);
    }
  }
  return menu;
};

export const expandMenuItem = (
  event: TreeViewExpandChangeEvent,
  menu: IAdminMenuItem[],
) => {
  const itemIndex = menu.indexOf(event.item);
  const newTree = structuredClone(menu);
  newTree[itemIndex] = {
    ...newTree[itemIndex],
    expanded: !newTree[itemIndex].expanded,
  };
  return newTree;
};

export const getPrivilegedCurrentMenu = (
  privileges: ModulePrivileges[],
  location: string,
) => {
  const clonedMenu = structuredClone(menu);
  const privilegedMenu = applyPrivilegesToMenu(clonedMenu, privileges);
  const privilegedCurrentMenu = applyCurrentItemToMenu(
    privilegedMenu,
    location,
  );
  return privilegedCurrentMenu;
};

export const findInMenuTree = (
  predicate: (item: IAdminMenuItem) => boolean | undefined,
  tree: IAdminMenuItem[],
): IAdminMenuItem | null => {
  for (const item of tree) {
    if (item?.items?.length) {
      const childResult = findInMenuTree(predicate, item.items);
      if (childResult !== null) return childResult;
    } else {
      if (predicate(item)) return item;
    }
  }
  return null;
};
