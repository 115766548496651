import { useMutation, useQuery } from 'urql';
import { graphql } from '../gql/index.ts';
import { useForm } from 'react-hook-form';
import { InputStateWrapper as HookedInput } from './form-components/InputStateWrapper.tsx';
import { MailSettings } from '../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import { useEffect, useRef } from 'react';
import { Label } from '@progress/kendo-react-labels';
import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { NumericInputStateWrapper } from './form-components/NumericInputStateWrapper.tsx';
import { Input } from './form-components/Input.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Link } from 'wouter';
import { CheckboxStateWrapper } from './form-components/CheckboxStateWrapper.tsx';
import { cloneAndRemoveTypename } from '../public/util.ts';

interface MailSettingsPageProps {
  companyId: string;
}

const MailSettingsQuery = graphql(/* GraphQL */ `
  query company($companyId: String!) {
    company(companyId: $companyId) {
      mailSettings {
        smtpHost
        smtpPort
        smtpUser
        smtpPassword
        smtpTls
        senderName
        senderMail
        allMailsSendToBcc
        sendConfirmationMail
        sendReminderSevenDays
        sendReminderOneDay
      }
    }
  }
`);

const SendTestMailMutation = graphql(/* GraphQL */ `
  mutation sendTestMail($companyId: String!, $receiver: String!) {
    sendTestMail(companyId: $companyId, receiver: $receiver)
  }
`);

const UpdateCompanyMutation = graphql(/* GraphQL */ `
  mutation updateCompany($companyId: String!, $company: UpdateCompanyDto!) {
    updateCompany(companyId: $companyId, company: $company) {
      id
      mailSettings {
        smtpHost
        smtpPort
        smtpUser
        smtpPassword
        smtpTls
        senderName
        senderMail
        allMailsSendToBcc
        sendConfirmationMail
        sendReminderSevenDays
        sendReminderOneDay
      }
    }
  }
`);

export const MailSettingsPage: React.FC<MailSettingsPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: MailSettingsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCompany] = useMutation(UpdateCompanyMutation);
  const [, sendTestMail] = useMutation(SendTestMailMutation);

  const testMailInputValueRef = useRef<HTMLInputElement>(null);

  const { control, formState, handleSubmit, reset } = useForm<MailSettings>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onFormSubmit = (values: MailSettings) => {
    updateCompany({
      companyId,
      company: { mailSettings: values },
    }).then((r) => {
      const withoutTypename =
        r.data?.updateCompany?.mailSettings &&
        cloneAndRemoveTypename(r.data?.updateCompany?.mailSettings);
      // TODO: Check for errors and indicate success/failure to UI
      reset(withoutTypename);
    });
  };

  useEffect(() => {
    if (!fetching) {
      const withoutTypename =
        data?.company?.mailSettings &&
        cloneAndRemoveTypename(data?.company?.mailSettings);
      reset(withoutTypename);
    }
  }, [fetching]);

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="E-Mail Einstellungen" />

      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="SMTP-Konfiguration"
          description="Das System verschickt alle automatisierten E-Mails über die angegebenen Konfigurationsdaten."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput
              name={'senderName'}
              label={'Absendername'}
              placeholder="Lisa Musterfrau"
              hint={'Dieser Name taucht beim Empfänger als Absender auf.'}
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <HookedInput
              name={'senderMail'}
              label={'Absender E-Mail'}
              placeholder="lisa@musterfrau.de"
              hint={'Diese Adresse taucht beim Empfänger als Absender auf.'}
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <div className="flex gap-4">
              <HookedInput
                name={'smtpHost'}
                label={'Serveradresse'}
                placeholder="smtp.musterfrau.de"
                hint={'Adresse des SMTP-Servers.'}
                rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
                wrapperClassName="flex-1"
                control={control}
              />
              <NumericInputStateWrapper
                name={'smtpPort'}
                label={'Port'}
                placeholder="587"
                hint="Üblicherweise 25, 587 oder 465"
                rules={{
                  required: 'Dieses Feld muss ausgefüllt werden!',
                  min: {
                    value: 0,
                    message: 'Wert muss zwischen 0 und 65536 liegen',
                  },
                  max: {
                    value: 65536,
                    message: 'Wert muss zwischen 0 und 65536 liegen',
                  },
                }}
                control={control}
              />
            </div>
            {/* <HookedDropdown
              name={'smtpEncryption'}
              label={'Verschlüsselung'}
              placeholder="Keine"
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
              options={[
                { text: 'STARTTLS', value: 'starttls' },
                { text: 'SSL/TLS', value: 'ssltls' },
                { text: 'Keine', value: 'none' },
              ]}
            /> */}
            <HookedInput
              name={'smtpUser'}
              label={'Benutzername'}
              placeholder="lisa-musterfrau"
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <HookedInput
              name={'smtpPassword'}
              label={'Passwort'}
              type="password"
              placeholder="abc123"
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <div>
              <Label className="k-form-label">Testmail versenden an</Label>
              <div className="flex gap-4">
                <Input
                  id="testMailReceiver"
                  name={'testMailReceiver'}
                  placeholder="max@mustermann.de"
                  wrapperClassName="flex-1"
                  ref={testMailInputValueRef}
                />
                <Button
                  type="button"
                  title="Speichern"
                  className="[&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                  onClick={() => {
                    if (testMailInputValueRef.current?.value) {
                      sendTestMail({
                        companyId,
                        receiver: testMailInputValueRef.current?.value,
                      }).then((r) => {
                        console.log(r);
                      });
                    }
                  }}
                >
                  <HeroIcon name="PaperAirplane" className="block h-5 w-5" />
                  <div>Testen</div>
                </Button>
              </div>
            </div>
          </div>
        </Fieldset>

        <Fieldset
          legend="Empfänger"
          description="Zusätzliche Empfänger für automatisierte E-Mails."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput
              name={'allMailsSendToBcc'}
              label={'BCC-Empfänger für alle E-Mails'}
              hint={
                'Alle E-Mails werden zusätzlich an diese E-Mail verschickt.'
              }
              control={control}
            />
            {/* <HookedInput
              name={'receiverNotifications'}
              label={'Empfänger für Notifications'}
              control={control}
            /> */}
            {/* <CheckboxStateWrapper
              name={'sendNotificationAtRegistration'}
              label={'Benachrichtigung bei neuer Anmeldung'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'sendNotificationOnUpdateSeminar'}
              label={'Benachrichtigung bei Seminaraktualisierung'}
              control={control}
            /> */}
          </div>
        </Fieldset>
        <Fieldset
          legend={`Vorlagen`}
          description="Definieren Sie verschiedene E-Mail Vorlagen für alle E-Mails die das System versendet."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <style>
              {`
              .templateGrid .k-grid-header col:nth-of-type(1){
                width: 80%
              }
              .templateGrid .k-grid-table col:nth-of-type(1){
                width: 80%
              }
              .templateGrid .k-grid-header col:nth-of-type(2){
                width: 20%
              }
              .templateGrid .k-grid-table col:nth-of-type(2){
                width: 20%
              }`}
            </style>
            <Grid
              className="templateGrid h-auto w-full [&_.k-grid-norecords-template]:border-0"
              data={[
                {
                  templateName: 'Bestätigungsmail Seminaranmeldung',
                  slug: 'signup-confirmation',
                },
                {
                  templateName: 'Erinnerungsmail 7 Tage',
                  slug: 'reminder-seven-days',
                },
                {
                  templateName: 'Erinnerungsmail Vortag',
                  slug: 'reminder-one-day',
                },
              ]}
            >
              <GridNoRecords>Keine Vorlage vorhanden</GridNoRecords>
              <GridColumn title="Vorlage" field={'templateName'} />
              <GridColumn
                title="Aktionen"
                cell={(props) => (
                  <td className={'k-table-td'}>
                    <div className="k-button-link-secondary flex gap-1">
                      <Link
                        href={`/mailsettings/templates/${props.dataItem.slug}`}
                        className="k-button k-button-md k-rounded-md k-button-outline k-button-outline-secondary"
                        title="Feld bearbeiten"
                      >
                        <HeroIcon name="Pencil" className="block h-5 w-5" />
                      </Link>
                    </div>
                  </td>
                )}
              />
            </Grid>
          </div>
        </Fieldset>
        <Fieldset
          legend={`Varianten`}
          description="Welche E-Mails möchten Sie verschicken?"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <CheckboxStateWrapper
              name={'sendConfirmationMail'}
              label={'Bestätigungsmail Seminaranmeldung'}
              hint={
                'Nach einer Anmeldung bei einem Seminar bekommt der Teilnehmer eine E-Mail.'
              }
              control={control}
            />
            <CheckboxStateWrapper
              name={'sendReminderSevenDays'}
              label={'Erinnerungsmail 7 Tage'}
              hint={
                'Sieben Tage vor Beginn des Seminars wird der Teilnehmer an das Seminar erinnert.'
              }
              control={control}
            />
            <CheckboxStateWrapper
              name={'sendReminderOneDay'}
              label={'Erinnerungsmail Vortag'}
              hint={
                'Einen Tag vor Beginn des Seminars wird der Teilnehmer an das Seminar erinnert.'
              }
              control={control}
            />
          </div>
        </Fieldset>
        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
