import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql } from '../gql';
import { useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { PublicSeminarPageFieldType } from '../gql/graphql.ts';
import { PublicDetailsSidebar } from './PublicDetailsSidebar.tsx';

const PublicSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSeminar($seminarId: String!, $companyId: String!) {
    publicSeminar(seminarId: $seminarId, companyId: $companyId) {
      id
      title
      subtitle
      seminarNumber
      startDate
      endDate
      dateText
      description
      locationText
      registrationDate
      price
      priceText
      price2
      priceText2
      price3
      priceText3
      price4
      priceText4
      isBookedOut
      isAlmostBookedOut
      canceled
      body {
        type
        html
      }
      lecturers {
        name
      }
      categories {
        name
      }
      signedUpAt
    }
  }
`);

interface Props {
  seminarId: string;
  companyId: string;
}

export const PublicSeminar: React.FC<Props> = ({ seminarId, companyId }) => {
  const [{ data }] = useQuery({
    query: PublicSeminarQuery,
    variables: {
      companyId,
      seminarId,
    },
    pause:
      companyId === undefined ||
      companyId === null ||
      seminarId === undefined ||
      seminarId === null,
  });

  const { publicSeminar: seminar } = data || {};

  const [publicCompanyData] = usePublicCompany();

  const getHtmlElement = (
    type: PublicSeminarPageFieldType,
    content: string,
  ) => {
    const commonBoxClasses = 'border px-4 py-3 rounded relative my-2';
    // Would love to replace this with an actual React component <Alert>, but this is raw html injected into the DOM, so can't use React components here
    const mapping: Record<string, string> = {
      error: `<div class="bg-red-100 border-red-400 text-red-700 prose-headings:text-red-700 ${commonBoxClasses}">${content}</div>`,
      info: `<div class="bg-sky-100 border-sky-400 text-sky-700 prose-headings:text-sky-700 ${commonBoxClasses}">${content}</div>`,
      warning: `<div class="bg-yellow-100 border-yellow-400 text-yellow-700 prose-headings:text-yellow-700 ${commonBoxClasses}">${content}</div>`,
      notice: `<div class="bg-slate-100 border-slate-400 text-slate-700 prose-headings:text-slate-700  ${commonBoxClasses}">${content}</div>`,
      heading: `<div class="font-bold text-3xl my-2">${content}</div>`,
      normal: `<div>${content}</div>`,
    };
    return mapping[type?.toLowerCase() || 'normal'];
  };

  const bodyHtml = seminar?.body
    ?.map(
      (item) => item.type && item.html && getHtmlElement(item.type, item.html),
    )
    .filter(Boolean)
    .join('');

  const hasWaitlist =
    publicCompanyData?.functionalSettings?.waitlistActive &&
    seminar?.isBookedOut;

  const isBeforeRegistrationDate = seminar?.registrationDate
    ? new Date(seminar?.registrationDate) > new Date()
    : true;

  const isBeforeEndOfSeminar = new Date(seminar?.endDate) > new Date();

  return (
    <>
      <Helmet>
        <title>
          {seminar?.title || 'Seminar'} - {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>
      {/* <div className="col-span-12"><h2>Seminardetails</h2></div> */}
      <div className="col-span-1 flex flex-col gap-4 md:col-span-9">
        <SeminarCard
          singleView
          id={seminar?.seminarNumber}
          title={seminar?.title}
          description={bodyHtml}
          subtitle={seminar?.subtitle}
          category={
            seminar?.categories?.find((c) => Boolean(c.name.trim()))?.name
          }
          isSignedUp={Boolean(seminar?.signedUpAt)}
          signUpLink={
            publicCompanyData?.functionalSettings.registrationActive &&
            isBeforeEndOfSeminar &&
            isBeforeRegistrationDate &&
            !seminar?.canceled &&
            (!seminar?.isBookedOut || hasWaitlist)
              ? '/sign-up'
              : undefined
          }
          signUpLinkCaption={
            hasWaitlist
              ? seminar?.signedUpAt
                ? 'Weitere Anmeldung auf der Warteliste'
                : 'Auf die Warteliste'
              : seminar?.signedUpAt
                ? 'Weitere Person anmelden'
                : 'Anmelden'
          }
          signUpLinkLogo={hasWaitlist ? 'QueueList' : 'AcademicCap'}
        />
      </div>
      {seminar && (
        <PublicDetailsSidebar
          seminar={seminar}
          showSignalLight={
            publicCompanyData?.visualSettings.showSignalLight &&
            !seminar.canceled
          }
        />
      )}
    </>
  );
};
