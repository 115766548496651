import { Fragment } from 'react';
import * as Hero24Solid from '@heroicons/react/24/solid';
import * as Hero24Outline from '@heroicons/react/24/outline';

interface HeroIconProps {
  name: string;
  className?: string;
  variant?: 'solid' | 'outline';
}

const variantMapping = {
  solid: Hero24Solid,
  outline: Hero24Outline,
};

export const HeroIcon: React.FC<HeroIconProps> = ({
  name,
  className,
  variant = 'outline',
  ...props
}: HeroIconProps) => {
  //@ts-expect-error Dynamic Import Types
  const Comp = variantMapping[variant][name + 'Icon'];
  return (
    <Fragment>
      {Comp ? <Comp className={className} {...props} /> : null}
    </Fragment>
  );
};
