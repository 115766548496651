import React from 'react';
import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Link } from 'wouter';
import { PageTitle } from '../PageTitle.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';

interface UsersPageProps {
  companyId: string;
}

const UsersQuery = graphql(/* GraphQL */ `
  query users($companyId: String!) {
    users(companyId: $companyId) {
      id
      email
      firstName
      lastName
    }
  }
`);

const DeleteUserMutation = graphql(/* GraphQL */ `
  mutation deleteUser($companyId: String!, $userId: String!) {
    deleteUser(companyId: $companyId, userId: $userId)
  }
`);

export const UsersPage: React.FC<UsersPageProps> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }, reExecuteUsersQuery] = useQuery({
    query: UsersQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, deleteCompanySite] = useMutation(DeleteUserMutation);

  const deleteUser = (userId: string) => {
    deleteCompanySite({ companyId, userId }).then(() => reExecuteUsersQuery());
  };

  return (
    <>
      <PageTitle title="Benutzer" />

      <div className={'mb-4 flex justify-end'}>
        <Link
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          href={'/users/new'}
        >
          <HeroIcon name="UserPlus" className="h-5 w-5" />
          Neuer Benutzer
        </Link>
      </div>

      <Grid
        className="h-auto [&_.k-grid-norecords-template]:border-0"
        data={data?.users}
        pageable
      >
        <GridNoRecords>Keine Benutzer gefunden</GridNoRecords>
        <GridColumn field="email" title="Email" />
        <GridColumn field="firstName" title="Vorname" />
        <GridColumn field="lastName" title="Nachname" />
        <GridColumn
          width={'120px'}
          cell={(props) => (
            <td className={'k-button-link-secondary flex space-x-2'}>
              <Link
                href={`/users/edit/${props.dataItem.id}`}
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md !text-[#6c757d] hover:!text-white"
              >
                <HeroIcon
                  name="Pencil"
                  className="block h-5 w-5"
                  aria-label="Benutzer bearbeiten"
                />
              </Link>
              <button
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                onClick={() => deleteUser(props.dataItem.id)}
                title="Benutzer löschen"
              >
                <HeroIcon name="Trash" className="block h-5 w-5" />
              </button>
            </td>
          )}
        />
      </Grid>
    </>
  );
};
