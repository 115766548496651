import { HeroIcon } from '../shared/HeroIcon.tsx';

export interface PaginationProps {
  value: number;
  onChange: (value: number) => void;
  countPages: number;
}

export const Pagination: React.FC<PaginationProps> = ({
  value,
  onChange,
  countPages,
}) => {
  const maxPageLimit = 9;
  const actualMaxPageAmount = Math.min(maxPageLimit, countPages);

  return (
    <div className="flex items-center justify-between border-t py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => onChange(value - 1)}
          disabled={value === 0}
          className="inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Zurück
        </button>
        <button
          onClick={() => onChange(value + 1)}
          disabled={value === countPages - 1}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Vor
        </button>
      </div>
      <div className="hidden justify-center sm:flex sm:flex-1 sm:items-center">
        <nav
          className="isolate inline-flex -space-x-px rounded-md bg-white"
          aria-label="Pagination"
        >
          <button
            onClick={() => {
              onChange(0);
            }}
            disabled={value === 0}
            className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${value === 0 ? '' : 'cursor-pointer'}`}
          >
            <span className="sr-only">Erste Seite</span>
            <HeroIcon
              name="ChevronDoubleLeft"
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => onChange(value - 1)}
            disabled={value === 0}
            className={`relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${value === 0 ? '' : 'cursor-pointer'}`}
          >
            <span className="sr-only">Vorherige Seite</span>
            <HeroIcon
              name="ChevronLeft"
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          {/* Current: "z-10 bg-bts-orange text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bts-orange", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
          {[...Array(actualMaxPageAmount)].map((_, i) => {
            let j;
            if (value < Math.ceil(actualMaxPageAmount / 2)) {
              j = i;
            } else if (
              value >
              countPages - Math.ceil(actualMaxPageAmount / 2)
            ) {
              j = countPages - actualMaxPageAmount + i;
            } else {
              j = value - Math.floor(actualMaxPageAmount / 2) + i;
            }
            return (
              <button
                key={j}
                aria-current="page"
                onClick={() => onChange(j)}
                disabled={value === j}
                className={
                  '${relative inline-flex items-center px-4 py-2 text-sm font-semibold ' +
                  (j === value
                    ? 'z-10 bg-bts-theme-primary text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bts-theme-primary'
                    : 'cursor-pointer text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0')
                }
              >
                {j + 1}
              </button>
            );
          })}
          <button
            onClick={() => onChange(value + 1)}
            disabled={value === countPages - 1}
            className={`relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${value === countPages - 1 ? '' : 'cursor-pointer'}`}
          >
            <span className="sr-only">Nächste Seite</span>
            <HeroIcon
              name="ChevronRight"
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => onChange(countPages - 1)}
            disabled={value === countPages - 1}
            className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${value === countPages - 1 ? '' : 'cursor-pointer'}`}
          >
            <span className="sr-only">Letzte Seite</span>
            <HeroIcon
              name="ChevronDoubleRight"
              className="h-5 w-5"
              aria-hidden="true"
            />
          </button>
        </nav>
      </div>
    </div>
  );
};
