import { FieldWrapper } from '@progress/kendo-react-form';
import {
  Input as KendoInput,
  InputProps as KendoInputProps,
} from '@progress/kendo-react-inputs';

import { Label, Error, Hint } from '@progress/kendo-react-labels';
import React from 'react';

export interface InputProps extends KendoInputProps {
  validationMessage?: string;
  touched?: boolean;
  label?: string;
  id: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
  optional?: boolean;
  required?: boolean;
  wrapperClassName?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      validationMessage,
      touched,
      label,
      id,
      disabled,
      hint,
      type,
      optional,
      wrapperClassName,
      required,
      ...others
    },
    ref,
  ) => {
    const showValidationMessage = Boolean(touched && validationMessage);
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    return (
      <FieldWrapper className={wrapperClassName}>
        {label && (
          <Label
            editorId={id}
            editorValid={!showValidationMessage}
            editorDisabled={disabled}
            optional={optional}
            className="k-form-label"
          >
            {label} {required ? <span className="text-sm">*</span> : ''}
          </Label>
        )}
        <div className={'k-form-field-wrap'}>
          <KendoInput
            ref={ref}
            valid={!showValidationMessage}
            type={type}
            id={id}
            disabled={disabled}
            ariaDescribedBy={`${hintId} ${errorId}`}
            required={required}
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    );
  },
);
